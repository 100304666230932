body {
    background-color: #f8f9fa;
    overflow-x: hidden;
}

a {
    text-decoration: none;
}

.admin {
    position: relative;
}

.top-background {
    background-color: #5e72e4;
    min-height: 300px;
}

/* SideNav */
.sidenav {
    background-color: #fff;
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    max-width: 15.625rem;
    overflow-y: auto;
    padding: 0;
    box-shadow: none;
    z-index: 1;
    box-shadow: 0 0 2rem 0 rgba(136, 152, 170, .15);
    left: 0;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 1.5rem;
    border-radius: 1rem;
}

.sidenav-header {
    height: 4.875rem;
    text-align: center;
}

.brand-logo img {
    height: 50px;
    margin: 1.5rem 2rem;
}
.sidenav .navbar-nav .nav-item {
    padding: 0;
    line-height: inherit;
}
.sidenav .navbar-nav .nav-link {
    margin: 0 .04rem;
    padding: .675rem 1rem;
    font-weight: 400;
    color: #67748e;
    cursor: pointer;
}

.sidenav .navbar-nav .nav-link:focus,
.sidenav .navbar-nav .nav-link:active,
.sidenav .navbar-nav .nav-link.active,
.sidenav .navbar-nav .nav-link:hover {
    font-weight: 600;
    color: #fff;
    background-color: #5e72e4;
}

.sidenav .navbar-nav .nav-link svg {
    margin-right: .5rem;
    position: relative;
    top: -2px;
    font-size: 24px;
}

.sidenav::-webkit-scrollbar {
    width: 6px;
}

.sidenav::-webkit-scrollbar-track {
    border-radius: 10px;
}

.sidenav::-webkit-scrollbar-thumb {
    background: #fff;
    border-radius: 10px;
}

.sidenav::-webkit-scrollbar-thumb:hover {
    background: #67748e;
}

.sidebar-closing {
    display: none;
}

@media screen and (min-width:992px) {
    .sidenav .navbar-collapse {
        display: block !important;
    }
}
@media screen and (max-width:991px) {
    .sidenav {
        transform: translateX(-17.125rem);
        transition: all 1s ease;
    }
    .sidenav hr {
        margin-top: 0;
    }
    .sidenav.mobile-sideView {
        margin-left: 0.7rem;
        transform: translateX(0);
        transition: all 1s ease;
        z-index: 2;
    }
    .brand-logo {
        position: relative;
    }
    .brand-logo img {
        margin: 1rem 2rem;
    }
    .sidebar-closing {
        display: block;
        position: absolute;
        right: 12px;
        top: 12px;
        font-size: 30px;
        opacity: 0.7;
    }
}

/* SideNav */

/* Main */
main {
    position: absolute;
    top: 0;
    width: -webkit-fill-available;
    margin-left: 17.125rem;
    transition: all .2s ease-in-out;
}

main .admin-navbar {
    margin: 0.8rem 1.5rem;
}

main .admin-navbar .nav-item {
    list-style: none;
}

main .admin-navbar .btn-group .btn {
    background: #fff;
    border: none;
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: inherit !important;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    padding: 0;
}

.admin-navbar .dropdown-item {
    line-height: 20px;
}

.sidenav-toggler-inner {
    display: none;
 }
.sidenav-toggler-inner svg {
   font-size: 30px;
   color: #fff;
   margin-left: 15px;
}

main .admin-body-layout {
    min-height: 71vh;
}
@media screen and (max-width:991px) {
    main {
        margin-left: 0;
    }
    main .admin-navbar {
        margin: 0 0;
    }
    main .admin-navbar .nav-item {
        padding: 0.5rem 0px;
    }
    .blur-layout {
        opacity: 0.1;
        background-color: #000;
    }
    .sidenav-toggler-inner {
        display: block;
     }
}
/* Main */

/* Footer */
.copyright {
    font-size: 14px;
    padding: 15px;
    font-weight: 500;
}

.mitizcontent{
    color:black;
}
.mitizoverlay{
    background: rgb(233 220 220 / 45%) !important;
}
.table tr:last-child td{
    border-bottom: none;
}