body {
  position: relative;
}

.pages {
  color: #316685;
  text-align: center;
  font-size: calc(1.5rem + 2vw);
  margin-top: 10%;
}

.front-navbar {
  position: relative !important;
  width: 100%;
  top: 0;
  z-index: 20;
  transition: all 2s ease;
  display: inline-block;
  background-image: linear-gradient(110deg, #fff 10%, rgba(50, 0, 183) 90%, rgba(50, 0, 183) 100%) !important;
}

.navbar-blue,
.secondary-header .front-navbar {
  position: fixed !important;
  top: 0;
  background-image: linear-gradient(135deg, #fff 10%, rgba(50, 0, 183) 90%, rgba(50, 0, 183) 100%) !important;
  width: 100%;
  opacity: 1;
  z-index: 2;
}

.nav-container {
  width: 100%;
}

.main-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.front-navbar .toggle:focus,
.navbar-blue .toggle:focus {
  box-shadow: none;
}

.front-navbar .toggle {
  height: 63px !important;
}

.nav-logo {
  align-items: center;
  cursor: pointer;
  flex-grow: 1;
  display: inline-block;
  padding: 10px 0;
}

.nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
  margin-bottom: 0;
  width: 88%;
  float: right;
}

.nav-menu ul {
  list-style: none;
  margin-left: auto;
  display: flex;
  margin-bottom: 0;
}

.nav-links {
  color: rgba(255, 255, 255, 0.95);
  text-decoration: none;
  padding: 0.5rem 0.2rem;
  height: 100%;
}

.nav-links:hover {
  color: rgba(255, 255, 255, 1);
}

.nav-links svg {
  font-size: 14px;
}

.fa-code {
  margin-left: 1rem;
}

.nav-item {
  line-height: 50px;
  color: #fff;
  font-weight: 500;
  padding: 0.5rem 1rem;
}

.nav-item:last-child {
  padding-right: 0;
}

.nav-item .btn {
  background-color: #00ba94;
  border-color: #00ba94;
}

.nav-item:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
}

.nav-item.active {
  color: #ffdd40;
}

.nav-icon {
  display: none;
}

.nav-menu ul .toggle {
  background-color: transparent;
  border: none;
}

.nav-menu ul .toggle:active, .nav-menu ul .toggle:focus {
  background-color: transparent !important;
  border: none !important;
  outline: none;
}

@media screen and (max-width: 960px) {
  .front-navbar, .navbar-blue {
    background-image: linear-gradient(110deg, #fff 10%, rgb(255, 255, 255) 100%, rgba(255, 255, 255) 100%) !important;
  }
  .nav-logo {
    display: flex !important;
    padding: 8px 0 !important;
  }
  .front-navbar .nav-logo img {
    width: 100px;
  }
  .navbar-blue .nav-logo img {
    width: 85px;
  }
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1px solid #fff;
    position: absolute;
    top: 50px;
    left: -110%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .front-navbar .nav-menu.active, .navbar-blue .nav-menu.active {
    left: 0px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    padding-bottom: 30px;
    padding-left: 0;
    text-align: left;
    background-image: linear-gradient(-90deg, rgba(50, 0, 183) 80%, rgba(50, 0, 183) 100%) !important;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }
  
  .nav-menu ul {
    display: block;
    margin-left: 0;
  }
  

  .nav-item {
    line-height: 35px;
  }

  .nav-item .active {
    color: #ffdd40;
    border: none;
  }

  .nav-links {
    padding: 0;
    width: 100%;
    display: table;
  }

  .nav-icon {
    cursor: pointer;
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #714ecd;
  }

  .navbar-blue .nav-icon svg {
    font-size: 1.4rem;
  }
}

.dropbtn {
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  z-index: 1;
  top: 68px;
  text-align: left;
}

.dropdown-content a {
  color: #000;
  padding: 6px 12px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #4d19d2;
}

.dropdown:hover .dropdown-content {
  display: block;
}

/* Header */

/* Banner */
section {
  background-image: linear-gradient(135deg, #fff 10%, rgba(50, 0, 183) 60%, rgba(50, 0, 183) 100%) !important;
  color: #fff;
  height: 650px;
  padding: 150px 0 0;
  position: relative;
  top: -69px;
}

section h1 {
  text-shadow: 2px 2px 8px #5d5d5d;
}

section p {
  font-size: 1.25rem;
  color: #fff;
  text-shadow: 2px 2px 8px #5d5d5d;
}

section .btn {
  background-color: #00ba94;
  border-color: #00ba94;
}

@media screen and (max-width: 960px) {
  section {
    height: auto;
    padding: 95px 0 30px;
    background-image: linear-gradient(135deg, rgba(50, 0, 183) 70%, rgba(50, 0, 183) 60%, rgba(50, 0, 183) 100%) !important;
  }
}

.banner-arc {
  position: relative;
  margin-bottom: 100px;
  margin-bottom: 0;
}

.banner-arc svg {
  position: absolute;
  bottom: 0;
  bottom: 69px;
  left: 0;
  width: 100%;
  height: 3rem;
}

/* Banner */

/* Support */
.support_layout {
  margin-bottom: 55px;
}

.support_layout .card {
  border: 1px solid rgba(33, 40, 50, 0.125);
  border-radius: 0.35rem;
}

.support_layout .nav {
  background-color: #fff;
  border-radius: 0.35rem;
}

.support_aside .nav-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding: 0;
}

.support_aside .nav-item::after {
  display: none;
}

.support_aside .nav-item:last-child {
  border: none
}

.support_aside .nav-link {
  text-align: left;
  font-weight: 400;
  color: #212832;
  border: none !important;
  font-size: 14px;
  cursor: pointer;
}

.support_aside .nav-link.active {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
}

.support_aside .nav-link svg {
  margin-right: 10px;
  vertical-align: sub;
  color: #c5ccd6;
}

.support_aside .nav-link:hover,
.support_aside .nav-link.active {
  border: none;
  background-color: #f2f6fc !important;
  color: #4a515b !important;
}

.support_article {
  text-align: left;
}

.badge {
  background-color: #f2eee3;
  font-size: 14px !important;
}

/* Support */


/* Error */
.front-navbar .px-5 {
  padding-right: 2.5rem !important;
  padding-left: 2.5rem !important;
}

/* Error */

/* Footer */
.footer-arc {
  position: relative;
}

.footer-arc svg {
  position: absolute;
  height: 3rem;
  bottom: 0;
  left: 0;
  width: 100%;
  color: #212832;
}

.frontend-footer {
  background-color: #212832;
  color: rgba(255, 255, 255, 0.6);
}

.frontend-footer ul {
  list-style: none;
  padding: 0;
  margin: 0;
  font-weight: 400;
}

.frontend-footer ul li {
  margin-bottom: 0.5rem !important;
}

.social_media li {
  float: left;
  padding-right: 15px;
}

.frontend-footer .policy {
  float: right
}


.toggle {
  --bs-btn-color: #fff;
  --bs-btn-bg: #4C1CD4 !important;
  --bs-btn-border-color: #4C1CD4 !important;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #4C1CD4 !important;
  --bs-btn-hover-border-color: #4C1CD4 !important;
  --bs-btn-focus-shadow-rgb: #4C1CD4 !important;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4C1CD4 !important;
  --bs-btn-active-border-color: #4C1CD4 !important;
  --bs-btn-active-shadow: #4C1CD4 !important;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #4C1CD4 !important;
  --bs-btn-disabled-border-color: #4C1CD4 !important;
}

.support_aside {
  cursor: pointer;
}

.toggle:active,
.toggle:focus {
  color: #fff !important;
  background-color: #4C1CD4 !important;
  border-color: #4C1CD4 !important;
  outline-style: solid;
  outline-color: #4C1CD4;
}

@media screen and (max-width: 767px) {
  footer h6 {
    margin-top: 20px;
  }

  footer .policy {
    float: left
  }
}

/* Footer */


/* Login */

.login label {
  color: #69707a;
  font-size: 0.875em;
}

.login .card-body {
  z-index: 1;
}

/* Login */



.react-tel-input .form-control {
  width: 100% !important;
}

.react-tel-input .country-list {
  width: 272px !important;
  background-color: #c5ccd6 !important;
}